/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: limin
 * @Date: 2021-09-18 09:35:02
 * @LastEditors: limin
 * @LastEditTime: 2023-09-21 11:49:10
 * @FilePath: \scrm-base\src\common\constants.js
 */
export default {
  SCRM_BASE_SESSION: 'SCRM_BASE_SESSION',
  SCRM_BASE_MENULIST: 'SCRM_BASE_MENULIST',
  SCRM_BASE_CONFIG: 'SCRM_BASE_CONFIG',
  SCRM_BASE_FUNCTIONAL_FLAG: 'function',
  SCRM_BASE_USERTYPE: {
    MERCHANT: '1',
    EMPLOYEE: '2'
  },
  SCRM_BASE_ERRORS: {
    NO_PERMISSION_MENUS: {
      CODE: '100',
      MESSAGE: '您的账户暂无任何功能权限，请联系管理员开通'
    },
    ILLEGAL_ROUTER_NO_THIRD_ROUTE: {
      CODE: '101',
      MESSAGE: '未分配任何子菜单'
    }
  },
  ICONS: (() => {
    const iconFiles = require.context('../assets/images/icons-v2', true, /\.png$/)
    return iconFiles.keys().map(iconPath => iconPath.replace(/^\.\/(.*)\.\w+$/, '$1'))
  })(),
  DEFAULT_ICON_NAME: 'default'
  // SCRM_BASE_UPDATE_LOG_VERSION: 'SCRM_BASE_UPDATE_LOG_CONFIG'
}
