<template>
  <div class="side-bar">
    <div class="logo">
      <Logo></Logo>
    </div>
    <div class="side-bar-menu">
      <GLScroll class="side-bar-menu-scroll-y">
        <div class="side-bar-menu-left">
          <el-menu :collapse="menu.isCollapse" class="flex-1 none-border" background-color="#fff" @open="open" @close="close" active-text-color="#409EFF" text-color="#999" :default-openeds="current.openeds" :default-active="current.active" :unique-opened="true" :collapse-transition="false">
            <SubMenu :subMenu="app.menuList.noHidden()"></SubMenu>
          </el-menu>
        </div>
      </GLScroll>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SideMenu',
  computed: {
    ...mapGetters(['app', 'menu']),
    current() {
      const cur = { active: '', openeds: [] }
      if (this.app.currentPage) {
        const [lv1, lv2] = this.app.currentPage.id.split('-')
        cur.openeds = [lv1]
        cur.active = `${lv1}-${lv2}`
      }
      return cur
    }
  },
  methods: {
    ...mapActions('menu', ['setOpenId', 'setHoverId']),
    open(index) {
      this.setOpenId(index)
    },
    close() {
      this.setOpenId('')
      // this.setHoverId('')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/collapse.scss';
.side-bar {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > .logo {
    flex: 0 0 $logo-height;
  }
  .side-bar-menu {
    flex: 1;
    user-select: none;
    overflow-y: auto;
    background-color: #fff;
    &-left {
      height: 100%;
      width: $menu-width;
      .el-menu {
        height: 100%;
      }
    }
    &-scroll-y {
      /deep/.el-scrollbar__wrap {
        margin-bottom: 0 !important ;
      }
      height: calc(100vh - #{$logo-height});
    }
  }
}
</style>
