import { mapGetters } from 'vuex'
export const currentId = {
  computed: {
    ...mapGetters(['app']),
    current() {
      let _current = {}
      if (this.app.currentPage) {
        const [lv1, lv2, lv3, lv4] = this.app.currentPage.id.split('-')
        Object.assign(_current, { lv1, lv2, lv3, lv4 })
      }
      return _current
    }
  }
}
