import { render, staticRenderFns } from "./MenuLv4.vue?vue&type=template&id=34ca4304&scoped=true&"
import script from "./MenuLv4.vue?vue&type=script&lang=js&"
export * from "./MenuLv4.vue?vue&type=script&lang=js&"
import style0 from "./MenuLv4.vue?vue&type=style&index=0&id=34ca4304&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34ca4304",
  null
  
)

export default component.exports