<template>
  <div class="scrm-base-mebu-lv4" v-show="nav4 && nav4.length">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :name="item.path" v-for="item in nav4" :key="item.id">
        <span slot="label" style="padding: 0 10px">{{ item.title }}</span>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { currentId } from './mixins/index'
import { utils } from 'glsx-common'
export default {
  name: 'MenuLv4',
  inject: ['bus'],
  mixins: [currentId],
  data() {
    return {
      activeName: '',
      nav4: []
    }
  },
  // watch: {
  //   current: {
  //     handler() {
  //       this.onLoad()
  //     },
  //     immediate: true
  //   }
  // },
  mounted() {
    this.bus.$on('menu3Changed', this.onMenu3Changed)
    this.onLoad()
  },
  beforeDestroy() {
    this.bus.$off('menu3Changed', this.onMenu3Changed)
  },
  methods: {
    handleClick(item) {
      this.$router.push(item.name)
    },
    setActive(activeId = '', nav4 = []) {
      this.nav4 = nav4
      if (nav4.length) {
        const sub = nav4.find(({ id }) => id == activeId)
        if (sub) {
          this.$nextTick(() => {
            console.log(sub.path)
            this.$router.push(sub.path)
            this.activeName = sub.path
          })
        }
      }
    },
    onMenu3Changed(menus = []) {
      this.setActive(menus[0]?.id, menus)
    },
    find(id) {
      const item = utils.array.valInDeep(this.app.menuList, id, 'id', 'children') || []
      return item[0]
    },
    onLoad() {
      const { lv1, lv2, lv3, lv4 } = this.current
      if (lv1 && lv2 && lv3 && lv4) {
        const lv3id = `${lv1}-${lv2}-${lv3}`
        const lv4id = `${lv3id}-${lv4}`
        const sub = this.find(lv3id)?.children || []
        const nav4 = sub.noHidden()
        this.setActive(lv4id, nav4)
      } else {
        this.setActive('', [])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins.scss';
.scrm-base-mebu-lv4 {
  // transform: translateY(-5px);
  @include leftpadding;
  /deep/.el-tabs {
    &__nav-wrap {
      &::after {
        height: 1px;
        // width: calc(100% - #{$left});
      }
    }
    &__header {
      margin: 4px 0 1px;
    }
    &__item {
      height: 42px !important;
      // line-height: 10px !important;
      &.is-active,
      &:hover {
        color: $main-color;
        font-weight: 500;
      }
      font-weight: 400;
      font-size: 16px;
      color: #717171;
      height: 36px;
      line-height: 36px;
    }
    &__active-bar {
      background-color: $main-color;
    }
  }
}
</style>
