<template>
  <div :class="['com-left-side-menu-lv1-item', isActive]" @mouseenter.stop="mouseenter" @mouseleave.stop="mouseleave" @click.stop="handleClick()">
    <i :class="['i', icon]"></i>
    <span class="title">{{ menuData.title }}</span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import { utils } from 'glsx-common'
import { currentId } from './mixins/index'
export default {
  name: 'LeftSideMenuLv1Item',
  inject: ['bus'],
  mixins: [currentId],
  props: {
    menuData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['app', 'menu']),
    icon() {
      return `i-${this.menuData.icon}${['', '-active'][+this.isHighlight]}`
    },
    isHighlight() {
      return this.app?.currentPage?.id?.split('-')[0] == this.menuData.id
    },
    isActive() {
      if (this.isHighlight) {
        return 'highlight'
      } else if (this.menu.hoverId == this.menuData.id) {
        return 'hover'
      }
      return ''
    }
  },
  methods: {
    mouseenter: function (e) {
      this.setHoverId(this.menuData.id)
      this.menu.isCollapse && this.bus.$emit('mouseenter', e.target, this.emitter)
    },
    mouseleave: function () {
      this.setHoverId('')
    },
    handleClick() {
      !this.menu.isCollapse && this.emitter()
    },
    emitter() {
      this.bus.$emit('menuChanged', { menus: this.menuData.children?.noHidden() })
    },
    ...mapActions('menu', ['setHoverId'])
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/collapse.scss';
.com-left-side-menu-lv1-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: $menu_width_collapse;
  height: 46px;
  gap: 4px;
  cursor: pointer;
  &.highlight {
    background-color: #edecff;
    .title {
      color: $main-color;
    }
  }
  &.hover {
    background-color: #f0f1f7;
  }
  .title {
    font-size: 10px;
    color: #666;
    // transform: scale(0.9);
    overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // max-width: 5em;
    // user-select: none;
  }
}
</style>
