/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: limin
 * @Date: 2021-06-07 14:12:32
 * @LastEditors: limin
 * @LastEditTime: 2021-10-25 20:55:46
 * @FilePath: \scrm\scrm-base\src\filters\index.js
 */
import Vue from 'vue'
import * as filter from './filter'
Object.keys(filter).forEach(k => Vue.filter(k, filter[k]))
