<template>
  <div class="scrm-base-submenu">
    <div v-for="item in subMenu" :key="item.id">
      <!-- 没有二级菜单的一级菜单 -->
      <div class="el-submenu" :class="{ 'is-active': active === item.id }" v-if="isMenuNoChildren(item)" @mouseenter.stop="mouseenter(item)" @mouseleave.stop="mouseleave" @click.stop="handleModuleClick(item)">
        <div class="el-submenu__title">
          <i class="i" :class="genClass(item)"></i>
          <span>{{ item.title }}</span>
        </div>
      </div>
      <!-- 有二级菜单的一级菜单 -->
      <el-submenu v-else :index="item.id" @mouseenter.native.stop="mouseenter(item)" @mouseleave.native.stop="mouseleave" popper-class="el-submenu-popper-base" hide-timeout="0" show-timeout="0">
        <template slot="title">
          <i class="i" :class="genClass(item)"></i>
          <span slot="title">{{ item.title }}</span>
        </template>
        <!-- 渲染二级菜单 -->
        <sub-menu-item :menuItems="item.children" :parentTitle="item.title"></sub-menu-item>
      </el-submenu>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SubMenu',
  props: {
    subMenu: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeId: '',
      hoverId: '',
      openId: ''
    }
  },
  computed: {
    ...mapGetters(['app', 'menu']),
    active() {
      if (this.app.currentPage) {
        const [nav1Id] = this.app.currentPage.id.split('-')
        return nav1Id
      }
      return '0'
    }
  },
  methods: {
    handleModuleClick({ children = [] }) {
      this.$router.push(children[0].path)
    },
    genClass: function ({ id, icon }) {
      return `i-${icon}${['', '-active'][+[this.active, this.menu.hoverId, this.menu.openId].includes(id)]}`
    },
    mouseenter: function (item) {
      if (this.menu.hoverId === item.id) return
      this.setHoverId(item.id)
    },
    mouseleave: function () {
      this.menu.hoverId && this.setHoverId('')
    },
    isMenuNoChildren({ children = [] }) {
      return children.noHidden().length == 0 && children[0]?.id?.split('-').length <= 2
    },
    ...mapActions('menu', ['setHoverId'])
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/style/collapse.scss';
.scrm-base-submenu {
  display: flex;
  flex-direction: column;
  .i {
    min-width: 20px;
    min-height: 20px;
  }
  /deep/.el-submenu {
    // &:not(.is-opened) {
    //   margin-bottom: $menu-item-mb;
    // }
    &__title {
      height: 60px;
      line-height: 60px;
      background-color: transparent !important;
      border-radius: 4px;
      padding-left: 28px !important;
      // margin: 10px 0;
      &:hover {
        background-color: #fff !important;
        span {
          color: $main-color !important;
        }
      }
      span {
        font-size: 18px;
        color: #666;
        // letter-spacing: 1.2px;
        font-weight: 600;
        margin-left: 10px;
      }
      .el-submenu__icon-arrow {
        display: none;
      }
    }
    &.is-opened {
      .el-submenu__title {
        background-color: transparent !important;
        span {
          color: $main-color !important;
        }
      }
    }
    &.is-active {
      .el-submenu__title {
        > span {
          color: $main-color;
        }
      }
    }
    .el-menu {
      transform: translate3d(0, -5px, 0);
    }
  }
}
</style>
