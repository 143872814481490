import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { Http } from 'glsx-common'
import '@/common/reg-prototype.js'
import '@/common/error-handler.js'
import { fetchConfig, config } from '@/config/index'
import '@/assets/style/index.scss'
import '@/assets/images/sprite/sprite.css'
import './components'
import './filters'
import { Message } from './plugins/element.js'

Vue.config.productionTip = false
Vue.prototype.$event = new Vue()
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://c4344b1745b14d839d6683e2b7aa5a44@sentry.glsx.com.cn/22',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'http://192.168.3.172', 'scrm.glsx.com.cn', 'scrm.test.glsx.com.cn', 'scrm.beta.glsx.com.cn', 'scrm.cgacar.com', 'scrm.beta.cgacar.com', /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}
var instance = null
async function render() {
  const cfg = await fetchConfig(false)
  const { response, request, sign, secret } = cfg
  Vue.use(config, cfg)
  const http = new Http({ response, request, sign, secret, alert: Message.error })
  Vue.prototype.$http = http
  instance = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#main')
}
export const http = params => Vue.prototype.$http(params)
export const This = key => instance[key]
render()
