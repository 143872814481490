/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: limin
 * @Date: 2021-11-22 19:08:41
 * @LastEditors: limin
 * @LastEditTime: 2021-11-22 19:29:48
 * @FilePath: \scrm\scrm-base\src\common\reg-prototype.js
 */
Array.prototype.noHidden = function () {
  return this.filter(item => !item.hidden)
}
