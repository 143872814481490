<template>
  <el-scrollbar class="gl-scroll">
    <slot></slot>
  </el-scrollbar>
</template>

<script>
export default {
  name: 'GLScroll'
}
</script>

<style lang="scss">
.gl-scroll {
  // flex: 1;
  > .el-scrollbar__wrap {
    overflow-x: hidden;
    margin-bottom: 0 !important ;
  }
  > .el-scrollbar__bar {
    z-index: 10;
    &.is-vertical {
      width: 6px;
    }
    &.is-horizontal {
      height: 6px !important;
      left: 0 !important;
    }
    /deep/ .el-scrollbar__thumb {
      background-color: rgba(144, 147, 153, 0.6);
    }
  }
}
</style>
