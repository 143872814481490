<!--
 * @Author: limin
 * @Date: 2021-08-19 11:16:08
 * @LastEditors: limin
 * @LastEditTime: 2023-08-22 16:07:37
 * @Description: 
-->
<template>
  <div class="com-nav-update-password">
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="712px" height="520px" :show-close="false" :destroy-on-close="true" :close-on-click-modal="false">
      <el-form :model="passwordForm" @keyup.enter.native="submitForm" :rules="rules" ref="passwordForm" class="updatePasswordForm">
        <el-form-item prop="password">
          <template slot="label">
            <span>初始密码</span>
          </template>
          <el-input type="password" clearable v-model.trim="passwordForm.password" placeholder="请输入密码" @keyup.enter.native="submitForm" show-password auto-complete="new-password"></el-input>
        </el-form-item>
        <el-form-item prop="newPassword">
          <template slot="label">
            <span>新密码</span>
            <span style="margin-left: 3em; font-size: 12px; font-weight: 400; color: #999">（必须包含6-20个字母+数字）</span>
          </template>
          <el-input type="password" clearable v-model.trim="passwordForm.newPassword" maxlength="20" placeholder="请输入新密码" @keyup.enter.native="submitForm" show-password></el-input>
        </el-form-item>
        <el-form-item prop="checkNewPassword">
          <template slot="label">
            <span>确认新密码</span>
            <span style="margin-left: 1em; font-size: 12px; font-weight: 400; color: #999">（必须包含6-20个字母+数字）</span>
          </template>
          <el-input type="password" clearable v-model.trim="passwordForm.checkNewPassword" maxlength="20" placeholder="请输入新密码" @keyup.enter.native="submitForm" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <div class="btn-wrapper">
          <el-button @click="cancle">取消</el-button>
          <el-button type="primary" @click="submitForm" :loading="loading">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import md5 from 'md5'
import { mapGetters, mapActions } from 'vuex'
import { utils } from 'glsx-common'
import api from '@/api'
import { Message } from '@/plugins/element'
export default {
  name: 'NavUpdatePassWord',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    var checkOldPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入旧密码。'))
      }
      if (md5(value) !== this.auth.session.pw) {
        return callback(new Error('您输入的密码不正确，请重新输入。'))
      }
      callback()
    }
    var validateNewPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入新密码。'))
      }
      const errText = validate(value)
      if (errText) {
        return callback(new Error(errText))
      }
      if (this.passwordForm.checkNewPassword) {
        this.$refs.passwordForm.validateField('checkNewPassword')
      }
      callback()
    }
    var validateNewPassword2 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请再次输入新密码。'))
      }
      const errText = validate(value)
      if (errText) {
        return callback(new Error(errText))
      }
      if (value !== this.passwordForm.newPassword) {
        return callback(new Error('两次输入的密码不一致，请重新输入。'))
      }
      callback()
    }
    var validate = value => {
      if (value.length < 6 || value.length > 20) {
        return '密码必须包含6-20个字符和数字。'
      }
      // if (!/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{6,20}$/.test(value)) {
      if (!/^(?=.*\d)(?=.*[a-zA-Z])[\s\S]{6,20}$/.test(value)) {
        return '您输入的密码格式不正确，必须同时包含字母、数字。'
      }
      return ''
    }
    return {
      passwordForm: {
        password: '',
        newPassword: '',
        checkNewPassword: ''
      },
      loading: false,
      rules: {
        password: [{ validator: checkOldPass, trigger: 'blur' }],
        newPassword: [{ validator: validateNewPassword, trigger: 'blur' }],
        checkNewPassword: [{ validator: validateNewPassword2, trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapGetters(['auth']),
    dialogVisible: {
      get() {
        return this.visible
      },
      set(v) {
        this.$emit('update:visible', v)
      }
    }
  },
  methods: {
    ...mapActions('app', ['logout']),
    submitForm: utils.debounce(function () {
      this.$refs['passwordForm'].validate(async valid => {
        if (valid) {
          this.loading = true
          const { response } = this.$config
          const { password, newPassword } = this.passwordForm
          const changeData = { oldPassword: md5(password), newPassword, sessionId: this.auth.session.token }
          let { code, message } = await api.app.updatePassword(changeData).catch(err => Message.error(`修改失败:${err}`))
          if (code === response.code_success_value) {
            Message.success('修改密码成功')
            setTimeout(() => {
              this.cancle()
              this.logoff()
            }, 1000)
          } else {
            Message.error(`修改密码失败:${message}`)
          }
          this.loading = false
        }
      })
    }, 500),
    cancle() {
      this.dialogVisible = false
      this.$refs['passwordForm'].resetFields()
    },
    logoff() {
      this.logout()
    }
  }
}
</script>
<style lang="scss" scoped>
.com-nav-update-password {
  /deep/.el-dialog {
    box-shadow: 0px 10px 20px 0px rgba(88, 80, 255, 0.1);
    &__header {
      padding: 0;
      background-color: $main-color;
    }
    &__title {
      color: #fff;
      height: 70px;
      line-height: 70px;
      text-align: center;
    }
    &__body {
      width: 342px;
      margin: 0 auto;
      padding: 40px 0 0 0;
      .el-form {
        &-item {
          text-align: left;
          margin-bottom: 18px;
          &__label {
            line-height: 30px;
            float: none;
          }
          &__content {
            .el-input__inner {
              &:focus {
                border-color: $main-color;
              }
            }
          }
        }
      }
    }
    &__footer {
      text-align: center;
      padding: 20px 0 60px 0;
      border-top: 0px;
      .btn-wrapper {
        margin: 0 auto;
        width: 342px;
        display: flex;
        justify-content: space-between;
        .el-button {
          width: 162px;
          &.el-button--primary {
            background-color: $main-color;
            border-color: $main-color;
            &:hover,
            &:focus {
              border-color: #7777f3;
              background-color: #7777f3;
            }
          }
          &.el-button--default {
            &:hover,
            &:focus {
              border-color: #7777f3;
              color: $main-color !important;
            }
          }
        }
      }
    }
  }
}
</style>
