<template>
  <div class="scrm-base-layout">
    <layout>
      <div id="subapp-viewport" class="app-view-box"></div>
    </layout>
  </div>
</template>

<script>
import Layout from '@/layout/index.vue'
import { registerApps } from '@/qiankun'
import { mapGetters } from 'vuex'
export default {
  components: {
    Layout
  },
  mounted() {
    if (!window.qiankunStarted) {
      window.qiankunStarted = true
      registerApps(this.app.menuList)
    }
  },
  computed: {
    ...mapGetters(['app'])
  }
}
</script>
<style lang="scss" scoped>
.scrm-base-layout {
  height: 100%;
}
// #subapp-viewport {
//   min-width: $right-min-width;
//   width: calc(100vw - #{$menu-width} - 2px);
// }
</style>
