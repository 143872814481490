/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: limin
 * @Date: 2021-06-07 14:12:32
 * @LastEditors: limin
 * @LastEditTime: 2022-11-14 10:09:19
 * @FilePath: \scrm-base\src\filters\filter.js
 */
export function accountLevel(value) {
  return ['-', '工厂', '省代', '市代', '门店'][value]
}
/**
 * 手机号码脱敏处理（150****7717）
 * @param {*} mobile
 */
export function encodeMobile(mobile) {
  if (!mobile) return ''
  return mobile.replace(mobile.substring(3, 7), '****')
}
