<template>
  <div class="com-bc3">
    <div class="back-wrap" v-show="breadcrumbs.isShowBack" @click="$router.back()">
      <div class="bt-back-wrap">
        <i class="i i-back"></i>
        <span class="back-text">返回</span>
      </div>
      <span class="sprator">|</span>
    </div>
    <div class="page-title">
      <el-breadcrumb separator="/" v-if="auth.session">
        <el-breadcrumb-item :to="{ path: ['', item.path][+isLink(item)] }" v-for="item in breadcrumbs.breads" :key="item.id" :class="{ isLink: isLink(item) }">{{ item.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import { Breadcrumb, BreadcrumbItem } from 'element-ui'
import { mapGetters } from 'vuex'
import { utils } from 'glsx-common'
export default {
  name: 'GLBreadcrumb3',
  components: {
    [Breadcrumb.name]: Breadcrumb,
    [BreadcrumbItem.name]: BreadcrumbItem
  },
  computed: {
    breadcrumbs() {
      const { currentPage, menuList: menus } = this.app
      if (!currentPage || !menus?.length) return []
      const { id: curId, title, navIds = curId.split('-'), curTitle = decodeURI(title), hidden } = currentPage
      const breads = navIds.reduce((navs, next) => {
        const { length, nextId = length ? `${navs[length - 1].id}-${next}` : next } = navs
        const [menu] = utils.array.valInDeep(menus, nextId, 'id', 'children')
        const { id, title, path, titleLogic = nextId === curId ? curTitle : title } = menu
        navs.push({ id, title: titleLogic, path })
        return navs
      }, [])
      const isShowBack = curId?.split('-').length > 2 && hidden
      return { breads, isShowBack }
    },
    ...mapGetters(['app', 'auth'])
  },
  methods: {
    isLink({ path }) {
      return path.startsWith('/') && !path.startsWith('/^') && !path.includes('@')
    }
  }
}
</script>
<style lang="scss" scoped>
$bread-item-color: #333 !important;
.com-bc3 {
  display: flex;
  align-items: center;
  .back-wrap {
    font-size: 12px;
    color: $bread-item-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    i {
      margin-right: 4px;
    }
    .sprator {
      margin: 0 8px;
      color: #c3c3c3;
    }
    .bt-back-wrap {
      width: 52px;
      height: 20px;
      line-height: 20px;
      background: #f2f2f2;
      border-radius: 10px;
      .back-text {
        font-weight: 400;
        color: #666;
      }
      &:hover {
        background: #e8e8e8;
        .back-text {
          color: #333;
        }
      }
    }
  }
  /deep/.el-breadcrumb {
    &__item {
      .el-breadcrumb__inner {
        color: $bread-item-color;
        font-weight: 500 !important;
        cursor: default !important;
        a:hover {
          font-weight: 500 !important;
        }
      }
      .el-breadcrumb__separator {
        color: $bread-item-color;
        font-weight: 500 !important;
        margin: 0 6px !important;
      }
      &:last-child {
        .el-breadcrumb__inner {
          text-decoration: none !important;
          color: $main-color !important;
          cursor: default !important;
        }
      }
      &.isLink:not(:last-child) {
        .el-breadcrumb__inner {
          &:hover {
            text-decoration: underline;
            cursor: pointer !important;
          }
        }
      }
    }
  }
}
</style>
