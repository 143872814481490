<template>
  <el-menu-item-group class="com-left-side-menu-lv3" v-if="menuItems">
    <el-menu-item v-for="item in menuItems.noHidden()" :key="item.id" :index="item.id" @click.native.stop="handleSelect(item)">
      <span>{{ item.title }}</span>
    </el-menu-item>
  </el-menu-item-group>
</template>

<script>
import { currentId } from './mixins/index'
export default {
  name: 'LeftSideMenuLv3',
  inject: ['bus'],
  mixins: [currentId],
  props: {
    menuItems: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleSelect({ path, children = [], id }) {
      const { lv1, lv2, lv3 } = this.current
      if (id == `${lv1}-${lv2}-${lv3}`) return
      if (path && path.startsWith('/') && !path.startsWith('/^')) {
        this.$router.push(path)
      }
      this.bus.$emit('menu3Changed', children.noHidden())
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/style/collapse.scss';
@import '~@/assets/style/mixins.scss';
$menu-item-width: 144px !important;
.com-left-side-menu-lv3 {
  width: $menu-item-width;
  margin-bottom: 1px;
  /deep/.el-menu-item-group__title {
    display: none;
  }
  /deep/ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
    .el-menu-item {
      width: 100% !important;
      min-width: 100% !important;
      height: 36px;
      line-height: 36px;
      align-self: center;
      border-radius: 4px;
      // padding-left: 0 !important;
      padding: 0 8px !important;
      span {
        @include menu-title-span-haspage;
      }
      &.is-active {
        @include menu-highlight;
        span {
          color: $main-color !important;
        }
      }
      &:hover:not(.is-active) {
        @include menu-title-hover;
      }
    }
  }
}
</style>
