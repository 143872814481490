<template>
  <div class="com-left-side-menu-lv2-item">
    <div v-for="item in menus" :key="item.id">
      <!-- 没有三级级菜单的二级菜单 -->
      <div class="el-submenu" :class="{ active: `${current.lv1}-${current.lv2}` == item.id }" v-if="isMenuNoChildren(item)" @click.stop="handleModuleClick(item)">
        <div class="el-submenu__title hasNoChild">
          <span>{{ item.title }}</span>
        </div>
      </div>
      <!-- 有三级菜单的二级菜单 -->
      <el-submenu v-else :index="item.id">
        <span slot="title" class="weaken">{{ item.title }}</span>
        <!-- 渲染三级菜单 -->
        <LeftSideMenuLv3 :menuItems="item.children"></LeftSideMenuLv3>
      </el-submenu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { currentId } from './mixins/index'
export default {
  name: 'LeftSideMenuLv2Item',
  mixins: [currentId],
  inject: ['bus', 'isPop'],
  props: {
    isPop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menus: []
    }
  },
  computed: {
    ...mapGetters(['app', 'menu'])
  },
  watch: {
    'menu.isCollapse': {
      handler() {
        this.reload()
      }
    },
    current: {
      handler() {
        this.reload()
      }
    }
  },
  mounted() {
    this.bus.$on('menuChanged', this.handleMenuChanged)
    this.reload()
  },
  beforeDestroy() {
    this.bus.$off('menuChanged', this.handleMenuChanged)
  },
  methods: {
    handleModuleClick({ path, id }) {
      const { lv1, lv2 } = this.current
      if (id == `${lv1}-${lv2}`) return
      if (path && path.startsWith('/') && !path.startsWith('/^')) {
        this.$router.push(path)
      }
      this.bus.$emit('menu3Changed', [])
    },
    isMenuNoChildren({ children = [] }) {
      return children.noHidden().length == 0
    },
    handleMenuChanged({ menus = [], isReload = false }) {
      // 如果是菜单收起状态 并且不是 popover 或者待更新的数据与当前数据想同 则不更新视图
      // if ((this.menu.isCollapse && !this.isPop) || this.menus[0]?.id == menus[0]?.id) return
      if (this.menus[0]?.id == menus[0]?.id) return
      if (isReload) {
        this.reload()
        return
      }
      // console.log('待修改:' + menus[0]?.title, '当前,' + this.menus[0]?.title)
      this.menus = menus
      this.bus.$emit('menuOpenedsChanged', { menus })
    },
    reload() {
      if (this.menu.isCollapse || this.isPop) return
      const { lv1 } = this.current
      const [pid] = this.menus[0]?.id.split('-') || []
      if (lv1 == pid) return
      console.log('reload')
      const menus = this.app.menuList.find(({ id }) => id == lv1).children.noHidden()
      this.handleMenuChanged({ menus })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/collapse.scss';
@import '~@/assets/style/mixins.scss';
$active-background-color: rgba(88, 80, 255, 0.2) !important;
.com-left-side-menu-lv2-item {
  height: 100%;
  padding: 8px;
  /deep/.el-submenu {
    height: 100%;
    border-radius: 4px;
    .el-menu {
      width: 144px !important;
    }
    // padding: 0 8px;
    &__title {
      height: 36px;
      line-height: 36px;
      background-color: transparent !important;
      padding: 0 8px !important;
      margin-bottom: 1px;
      span {
        @include menu-title-span-haspage;
      }
      .el-submenu__icon-arrow {
        // right: 0;
        visibility: hidden;
      }
      &:not(.hasNoChild) {
        // has children title
        cursor: default !important;
        border-bottom: 1px solid #eee;
        span {
          font-size: 13px !important;
          color: #999 !important;
          font-weight: 400 !important;
          cursor: default !important;
        }
      }
      &.hasNoChild:hover {
        @include menu-title-hover;
      }
    }
    // &.is-active {
    //   .el-submenu__title {
    //     background-color: transparent !important;
    //     span,
    //     i {
    //       color: $main-color !important;
    //     }
    //   }
    // }
    &.active {
      @include menu-highlight;
      span {
        color: $main-color !important;
      }
    }
  }
}
</style>
