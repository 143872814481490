<template>
  <div class="scrm-base-entry">
    <div id="main">
      <router-view></router-view>
    </div>
    <div class="el-loading-mask" v-show="app.isLoading" style="background-color: rgba(0, 0, 0, 0.1)">
      <div class="el-loading-spinner">
        <i class="el-icon-loading"></i>
      </div>
    </div>
    <!-- <div v-show="app.isLoadFaild" class="load-faild">加载失败,请检查服务是否开启</div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { helper } from '@/common'
export default {
  computed: {
    ...mapGetters(['app'])
  },
  mounted() {
    this.customized()
  },
  methods: {
    customized() {
      const { cdn, customized } = this.$config
      helper.customized(cdn, customized)
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/style/mixins.scss';
// 统一边距 begin
#app {
  background-color: transparent !important;
  padding: $left #{$left + 6px} $left $left !important;
  margin: 0 !important;
  position: relative !important;
}
// 统一边距 end
html {
  margin: 0;
  padding: 0;
  font-size: 625%;
  -webkit-text-size-adjust: 100%; // 4
  -ms-text-size-adjust: 100%; // 4
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // 6
  height: 100%;
  max-height: 100%;
  // overflow-x: hidden;
  // overflow-y: auto;
}
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  padding-left: calc(100vw - 100%);
  font-size: 14px;
  font-family: $font-family;
  @include hideScroll;
}
button,
button > span {
  font-family: $font-family !important;
}
.footer {
  box-shadow: 20px 10px 20px 10px rgba(88, 80, 255, 0.1);
}
.scrm-base-entry {
  height: 100%;
  .load-faild {
    text-align: center;
    color: #999;
    position: absolute;
    z-index: 1;
    top: 80px;
    left: 0;
    right: 0;
    pointer-events: none;
  }
  #main {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .el-loading-spinner {
    i,
    .el-loading-text {
      color: $main-color;
      font-size: 26px;
      margin-left: $menu-width;
    }
    .circular {
      height: 26px;
      width: 26px;
      .path {
        stroke: $main-color;
      }
    }
  }
}
</style>
