/*
 * @Descripttion:
 * @version:
 * @Author: limin
 * @Date: 2021-06-07 14:12:32
 * @LastEditors: limin
 * @LastEditTime: 2023-10-07 10:01:47
 */
// 根据环境引入不同配置 process.env.VUE_APP_ENV
let cfg = require('./conf.js')
import { Http, utils } from 'glsx-common'
import { constants } from '@/common'
import router from '../router'
const sw = !!+process.env.VUE_APP_CONFIG_SWITCH_LOCAL
export const fetchConfig = (isEnv = sw) => {
  if (isEnv) {
    return Promise.resolve(cfg)
  }
  const _config = utils.storage.getSession(constants.SCRM_BASE_CONFIG)
  const _session = utils.storage.getSession(constants.SCRM_BASE_SESSION)
  const _pathname = location.pathname
  const _login_path = _session?.loginpath
  const _constRoutes = router.constantRoutes.map(({ path }) => path)
  // 如果存在配置缓存,并且当前路径不是用以区分不同产品的路由(oss:运营版,login:基础版,sp:服务商版).说明是刷新页面,则直接返回缓存的配置.另外如果当前路由与登录时缓存的路由一致,也直接返回缓存配置.
  if ((_config && !_constRoutes.includes(_pathname)) || _pathname === _login_path || (_pathname === '/' && _login_path === '/login')) {
    return Promise.resolve(_config)
  }
  return new Http({ headers: { refererurl: _pathname }, request: { timeout: 30000 } }).get('https://oss-config.test.glsx.com.cn/mock/6136bea6ceeb5a5c2bec6950/base/config')
}
export const config = {
  install(vue, cfg) {
    cfg.isStandard = !cfg.customized || cfg.customized.assets !== 'cgacar'
    vue.prototype.$config = cfg
  }
}
export const configSwitch = sw
export default cfg
