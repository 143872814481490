import { utils } from 'glsx-common'
import { constants } from '@/common'
import { This } from '@/main'
import api from '@/api'
const auth = {
  namespaced: true,
  state: () => ({
    session: null,
    tracker: null
  }),
  mutations: {
    SET_SESSION(state, session) {
      state.session = session
    },
    async LOGOUT(state) {
      await api.app.logout({ sessionId: state.session.token })
    },
    CLEAR(state) {
      state.session = null
    }
  },
  actions: {
    setSession({ commit, dispatch }, session) {
      commit('SET_SESSION', session)
      dispatch('saveSession', session)
    },
    /* eslint-disable */
    saveSession({ commit }, session) {
      utils.storage.setSession(constants.SCRM_BASE_SESSION, session)
    },
    async logout({ commit }) {
      await commit('LOGOUT')
    },
    async permission({}, merchant) {
      const { response, productCode } = This('$config')
      /* eslint-disable */
      const { sessionId, permissionMerchantIds, uuid, loginList, ...merchantItems } = merchant
      let { code, message, data, menus, sessionData } = await api.app.permission({ productCode, sessionId })
      if (code == response.code_success_value) {
        menus = data[0]?.products[0]?.merchantProTreeResponses
        if (menus?.length) {
          data[0].active = true
          sessionData = { ...merchantItems, loginList: data, token: sessionId }
          // dispatch('setSession', { ...merchantItems, loginList: data, token: sessionId })
        } else {
          ;({ CODE: code, MESSAGE: message } = constants.SCRM_BASE_ERRORS.NO_PERMISSION_MENUS)
        }
      }
      return { code, message, menus, sessionData }
    },

    clear({ commit }) {
      commit('CLEAR')
    }
  }
}
export default auth
