import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce(
  (result, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    result.getters[moduleName] = state => state[moduleName]
    result.modules[moduleName] = value.default
    return result
  },
  { modules: {}, getters: {} }
)
export default new Vuex.Store(modules)
