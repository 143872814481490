<template>
  <div class="not-found">
    <h1 class="title">404 - 页面未找到</h1>
    <p class="message">很抱歉，我们无法找到您要访问的页面。</p>
    <router-link :to="{ path: auth.session.defaultPath }" class="link">返回首页</router-link>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'error404',
  computed: {
    ...mapGetters(['auth'])
  }
}
</script>
