<template>
  <div class="side-bar" @mouseleave.stop="mouseleave">
    <div class="logo">
      <Logo></Logo>
    </div>
    <div class="side-bar-menu">
      <LeftSideMenuLv1 :menuList="menuList"></LeftSideMenuLv1>
      <LeftSideMenuLv2 v-if="!menu.isCollapse"></LeftSideMenuLv2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LeftSideMenu',
  inject: ['bus'],
  provide() {
    return {
      isPop: false //LeftSideMenuLv2 组件判断是否POPover内
    }
  },
  computed: {
    ...mapGetters(['app', 'menu'])
  },
  data() {
    return {
      menuList: []
    }
  },
  created() {
    this.menuList = this.app.menuList.noHidden()
  },
  methods: {
    mouseleave: function () {
      // !this.menu.isCollapse && this.bus.$emit('menuChanged', { isReload: true })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/collapse.scss';
.side-bar {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  > .logo {
    flex: 0 0 $logo-height;
    height: $logo-height;
    z-index: 112;
  }
  .side-bar-menu {
    flex: 1;
    user-select: none;
    overflow: hidden;
    background-color: #fff;
    padding-top: 36px;
    display: flex;
  }
  &::before {
    position: absolute;
    content: '';
    width: 0;
    border-right: 1px solid #f5f7fa;
    height: 100%;
    left: calc(#{$menu_width_collapse} - 1px);
    z-index: 111;
  }
}
</style>
