<template>
  <div class="com-left-side-menu-lv2">
    <GLScroll class="com-left-side-menu-lv2-scroll-y">
      <el-menu class="flex-1 none-border" background-color="#fff" active-text-color="#409EFF" text-color="#999" :default-openeds="cur.openeds" :default-active="cur.active" :collapse-transition="false" ref="menus" @close="handleClose">
        <LeftSideMenuLv2Item></LeftSideMenuLv2Item>
      </el-menu>
    </GLScroll>
  </div>
</template>

<script>
import { currentId } from './mixins/index'
import { mapGetters } from 'vuex'
export default {
  name: 'LeftSideMenuLv2',
  mixins: [currentId],
  inject: ['bus', 'isPop'],
  created() {
    this.bus.$on('menuOpenedsChanged', this.handleMenuOpenedsChanged)
  },
  mounted() {
    this.handleCurrentChange(this.current)
  },
  beforeDestroy() {
    this.bus.$off('menuOpenedsChanged', this.handleMenuOpenedsChanged)
  },
  computed: {
    ...mapGetters(['menu'])
  },
  watch: {
    current: {
      handler(n) {
        this.handleCurrentChange(n)
      }
    }
  },
  data() {
    return {
      cur: {
        openeds: [],
        active: ''
      }
    }
  },
  methods: {
    handleCurrentChange({ lv1, lv2, lv3 }) {
      this.cur.active = `${lv1}-${lv2}-${lv3}`
    },
    handleMenuOpenedsChanged({ menus }) {
      if (this.menu.isCollapse && !this.isPop) return
      const _ids = menus.map(({ id, children = [] }) => {
        if (children.noHidden().length) {
          return id
        }
      })
      if (this.cur.openeds.toString() == _ids.toString()) return
      this.cur.openeds = _ids
    },
    handleClose(key, keyPath) {
      this.$refs.menus.open(keyPath)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/collapse.scss';
.com-left-side-menu-lv2 {
  width: 100%;
  user-select: none;
  overflow-y: auto;
  flex: 1;
  // width: calc(#{$menu_width} - #{$menu_width_collapse});
  /deep/.el-menu {
    height: 100%;
    width: calc(#{$menu_width} - #{$menu_width_collapse});
  }
  &-scroll-y {
    /deep/.el-scrollbar__wrap {
      margin-bottom: 0 !important ;
      .el-scrollbar__view {
        min-height: 52px;
        max-height: calc(100vh - #{$logo-height});
      }
    }
    height: 100%;
  }
}
</style>
