<template>
  <div class="com-up-log">
    <a :href="v.link" target="blank" @click="clickHandler()" :class="{ isNew: v.isNew }">更新日志</a>
  </div>
</template>

<script>
import { Version } from 'glsx-common-sdk'
import { mapGetters } from 'vuex'

const version = new Version({
  request: {
    baseUrl: 'https://config-center-backend.glsx.com.cn/glsxAdmin/config',
    isSign: false
  }
})
export default {
  name: 'NavUpdateLog',
  data() {
    return {
      v: {}
    }
  },
  mounted() {
    this.getUpdateLogCofig()
  },
  computed: {
    ...mapGetters(['auth'])
  },
  methods: {
    async getUpdateLogCofig() {
      const loginpath = this.auth?.session?.loginpath
      if (loginpath) {
        const product = loginpath === '/login' ? 'md' : loginpath.slice(1)
        this.v = await version.get({ moduleUrl: `scrm-${product}`, env: 'update' })
      }
    },
    clickHandler() {
      this.v = version.set()
    }
  }
}
</script>
<style lang="scss" scoped>
.com-up-log {
  position: relative;
  margin-right: 6px;
  .isNew:after {
    content: 'New';
    position: absolute;
    width: 30px;
    height: 16px;
    line-height: 16px;
    background: #eb4e4e;
    border-radius: 4px 4px 4px 0;
    top: -12px;
    right: -22px;
    color: #fff;
    font-size: 12px;
  }
}
</style>
