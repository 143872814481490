import { utils } from 'glsx-common'
import { constants } from '@/common'
// eslint-disable-next-line prettier/prettier
const modules = require.context('.', true, /^(?!.*\/init\.js$).*\.js$/).keys().map(fileName => fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
const init = {
  namespaced: true,
  actions: {
    async init({ dispatch, commit }) {
      // 获取页面持久化数据
      const menuList = utils.storage.getSession(constants.SCRM_BASE_MENULIST)
      const sessionInfo = utils.storage.getSession(constants.SCRM_BASE_SESSION)
      await dispatch('app/persisted', menuList, { root: true })
      await commit('auth/SET_SESSION', sessionInfo, { root: true })
      await dispatch('track/setTracker', sessionInfo, { root: true })
    },
    reset({ dispatch }) {
      modules.forEach(module => dispatch(`${module}/clear`, null, { root: true }))
    }
  }
}
export default init
