/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: limin
 * @Date: 2021-09-17 09:35:30
 * @LastEditors: limin
 * @LastEditTime: 2021-09-17 09:35:31
 * @FilePath: \scrm\scrm-base\src\layout\MessageSubscribe.js
 */
import { messenger } from '@/global'
import store from '../store'
messenger.subscribe(({ userInfo }) => {
  if (userInfo) {
    store.dispatch('user/updateUserInfo', userInfo)
  }
})
