<template>
  <el-breadcrumb separator="/" v-if="auth.session">
    <el-breadcrumb-item :to="{ path: auth.session.defaultPath }" class="isLink">首页</el-breadcrumb-item>
    <template v-if="isNotHome">
      <el-breadcrumb-item :to="{ path: ['', item.path][+isLink(item)] }" v-for="item in breadcrumbs" :key="item.id" :class="isLink(item) ? 'isLink' : 'noLink'">{{ item.title }}</el-breadcrumb-item>
    </template>
  </el-breadcrumb>
</template>

<script>
import { Breadcrumb, BreadcrumbItem } from 'element-ui'
import { mapGetters } from 'vuex'
export default {
  name: 'GLBreadcrumb',
  components: {
    [Breadcrumb.name]: Breadcrumb,
    [BreadcrumbItem.name]: BreadcrumbItem
  },
  computed: {
    breadcrumbs() {
      const { currentPage, menuList: menus, flatten: flattenMenus } = this.app
      if (!currentPage || !menus || !menus.length) return []
      const { id: curId, title, navIds = curId.split('-'), curTitle = decodeURI(title) } = currentPage
      return navIds.reduce((navs, next) => {
        const { length, nextId = length ? `${navs[length - 1].id}-${next}` : next } = navs
        const menu = flattenMenus.find(item => item.id === nextId)
        const { id, title, path, titleLogic = nextId === curId ? curTitle : title } = menu
        navs.push({ id, title: titleLogic, path })
        return navs
      }, [])
    },
    isNotHome() {
      const { currentPage } = this.app
      return currentPage && currentPage.path !== this.auth.session.defaultPath
    },
    ...mapGetters(['app', 'auth'])
  },
  methods: {
    isLink({ path }) {
      return path.startsWith('/') && !path.includes('@')
    }
  }
}
</script>
<style lang="scss" scoped>
$bread-item-color: #333 !important;
/deep/.el-breadcrumb {
  &__item {
    .el-breadcrumb__inner {
      color: $bread-item-color;
      font-weight: 500;
      cursor: default !important;
    }
    .el-breadcrumb__separator {
      color: $bread-item-color;
      font-weight: 500 !important;
    }
    &:last-child {
      .el-breadcrumb__inner {
        text-decoration: none !important;
        font-weight: 700 !important;
        color: $main-color !important;
        cursor: default !important;
      }
    }
    &.isLink {
      .el-breadcrumb__inner {
        &:hover {
          text-decoration: underline;
          cursor: pointer !important;
        }
      }
    }
  }
}
</style>
