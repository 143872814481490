<!--
 * @Author: limin
 * @Date: 2021-08-19 11:16:08
 * @LastEditors: limin
 * @LastEditTime: 2022-03-29 09:34:28
 * @Description: 
-->
<template>
  <div class="com-nav-contact">
    <div class="contact-box">
      <div class="contact-box-title" v-if="$config.isStandard">嘀加企微管家</div>
      <div class="contact-box-title">专属顾问</div>
      <div class="contact-box-content">
        <div class="contact-box-content-qrcode">
          <div class="contact-box-content-qrcode-image">
            <img :src="`${$config.cdn}/lianghh.png`" alt="" />
          </div>
          <div class="contact-box-content-qrcode-desc">扫码添加您的专属顾问</div>
        </div>
        <div class="contact-box-content-consult">
          <i class="el-icon-chat-dot-round"></i>
          <span>联系客服</span>
        </div>
        <div class="contact-box-content-time">
          <span>工作时间：9：00-18：00</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NavContact'
}
</script>
<style>
.el-popover {
  box-shadow: 0px 10px 20px 0px rgba(88, 80, 255, 0.1) !important;
  margin-bottom: 20px !important;
  padding: 20px !important;
  box-sizing: border-box !important;
}
</style>
<style lang="scss" scoped>
$color333: #333;
@mixin font($size: 14px, $weight: 400, $lineHeght: 24px, $color: $color333, $align: center, $spacing: 1px) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
  line-height: $lineHeght;
  letter-spacing: $spacing;
  text-align: $align;
}
.com-nav-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  .contact-box-title {
    @include font(20px, 600);
  }
  .contact-box-content {
    margin: 20px 0px 6px;
    &-qrcode {
      text-align: center;
      &-image {
        img {
          height: 100px;
          width: 100px;
          margin: 0 auto;
        }
      }
      &-desc {
        @include font();
        margin: 10px 0px;
      }
    }
    &-consult {
      @include font();
      i {
        font-size: 20px;
        color: #fe911a;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    &-time {
      @include font();
      margin-top: 5px;
    }
  }
}
</style>
