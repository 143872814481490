<template>
  <div class="scrm-base-sub-nav" v-show="subNavs && subNavs.length">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :name="item.path" v-for="item in subNavs" :key="item.id">
        <span slot="label" style="padding: 0 10px">{{ item.title }}</span>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SubNav',
  data() {
    return {
      activeName: '',
      cur2: {
        id: '',
        nav3: [],
        activeId: ''
      }
    }
  },
  mounted() {},
  methods: {
    handleClick(item) {
      this.$router.push(item.name)
    },
    setActive(activeId, id = this.cur2.id, nav3 = this.cur2.nav3) {
      const sub = this.find(activeId)
      if (sub) {
        this.cur2 = { activeId, id, nav3 }
        this.$nextTick(() => (this.activeName = sub.path))
      }
    },
    find(id) {
      return this.app.flatten.find(item => item.id == id)
    }
  },
  computed: {
    ...mapGetters(['app']),
    subNavs() {
      const { currentPage } = this.app
      if (currentPage) {
        const [lv1, lv2, lv3] = currentPage.id.split('-')
        const { id, nav3, activeId, lv2id = `${lv1}-${lv2}`, lv3id = `${lv2id}-${lv3}` } = this.cur2
        if (id === lv2id) {
          if (activeId !== lv3id) {
            this.setActive(lv3id)
          }
          return nav3
        }
        const sub = this.find(lv2id).children
        if (sub && sub.length) {
          const nav3 = sub.noHidden()
          this.setActive(lv3id, lv2id, nav3)
          return nav3
        }
      }
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins.scss';
.scrm-base-sub-nav {
  // transform: translateY(-5px);
  @include leftpadding;
  /deep/.el-tabs {
    &__nav-wrap {
      &::after {
        height: 1px;
        // width: calc(100% - #{$left});
      }
    }
    &__header {
      margin: 4px 0 1px;
    }
    &__item {
      height: 42px !important;
      // line-height: 10px !important;
      &.is-active,
      &:hover {
        color: $main-color;
        font-weight: 600;
      }
      font-weight: 400;
      font-size: 16px;
      color: #717171;
      height: 36px;
      line-height: 36px;
    }
    &__active-bar {
      background-color: $main-color;
    }
  }
}
</style>
