/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: limin
 * @Date: 2021-11-26 09:33:28
 * @LastEditors: limin
 * @LastEditTime: 2022-07-22 20:40:43
 * @FilePath: \scrm\scrm-base\src\common\error-handler.js
 */
import store from '@/store'
// 系统错误捕获
function errorHandler() {
  store.dispatch('app/setLoading', false)
}
window.onerror = errorHandler
