/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: limin
 * @Date: 2022-03-25 09:54:08
 * @LastEditors: limin
 * @LastEditTime: 2022-12-19 09:36:48
 * @FilePath: \scrm-base\src\common\helper.js
 */
import constants from './constants'
export function customized(cdn, { assets, title }) {
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = `${cdn}/assets/${assets}/favicon.ico`
  document.getElementsByTagName('head')[0].appendChild(link)
  document.title = title
}
export function getIcon(iconName) {
  const { ICONS, DEFAULT_ICON_NAME } = constants
  return ICONS.includes(iconName) && ICONS.includes(iconName + '-active') ? iconName : DEFAULT_ICON_NAME
}
export function locationPath() {
  return new Map(
    location.search
      .slice(1)
      .split('&')
      .map(kv => kv.split('='))
  )
}
