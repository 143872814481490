<template>
  <div class="com-bc2">
    <div class="back-wrap" v-show="page.isShowBack" @click="$router.back()">
      <i class="i i-back"></i>
      <span class="back-text">返回</span>
      <span class="sprator">|</span>
    </div>
    <div class="page-title">
      {{ page.title }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'GLBreadcrumb2',
  computed: {
    page() {
      const { id, hidden, title: pageTitle = '' } = this.app.currentPage || {}
      const isShowBack = id?.split('-').length > 2 && hidden
      const title = decodeURI(pageTitle)
      return { isShowBack, title }
    },
    ...mapGetters(['app', 'auth'])
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
$bread-item-color: #333 !important;
.com-bc2 {
  display: flex;
  align-items: center;
  font-weight: 600;
  .back-wrap {
    font-size: 12px;
    color: $bread-item-color;
    cursor: pointer;
    i {
      margin-right: 4px;
    }
    .sprator {
      margin: 0 8px;
      color: #c3c3c3;
    }
  }
  .page-title {
    font-size: 14px;
    color: $bread-item-color;
  }
}
</style>
