/*
所有模块都定义在 modules 
|-modules
|----demo.js
|----demo1.js
|----...
|-index.js   ---动态对外暴露所有模块 无须手动添加 

参考demo.js   用法 : this.$apis.demo.GETLISTDEVICEFILEUNSTOCK(...)
*/
const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})
export default modules
