import { tracker } from '@/global'
const menu = {
  namespaced: true,
  state: () => ({
    tracker: null
  }),
  mutations: {
    SET_TRACKER(state, tracker) {
      state.tracker = tracker
    },
    CLEAR(state) {
      state.tracker = null
    }
  },
  actions: {
    setTracker({ commit, rootGetters }, session) {
      /* eslint-disable */
      const { loginList, loginpath, pw, defaultPath, id, track, ...common } = session
      tracker.init({
        common,
        settings: { switch: track },
        getTitle: () => rootGetters?.app?.currentPage?.title || '未知'
      })
      commit('SET_TRACKER', tracker)
    },
    clear({ commit }) {
      commit('CLEAR')
    }
  }
}
export default menu
