const menu = {
  namespaced: true,
  state: () => ({
    hoverId: '',
    openId: '',
    isCollapse: true
  }),
  mutations: {
    SET_HOVER_ID(state, id) {
      state.hoverId = id
    },
    SET_OPEN_ID(state, id) {
      state.openId = id
    },
    TOGGLE(state) {
      state.isCollapse = !state.isCollapse
    },
    CLEAR(state) {
      state.openId = ''
      state.hoverId = ''
    }
  },
  actions: {
    setHoverId({ commit }, id) {
      commit('SET_HOVER_ID', id)
    },
    setOpenId({ commit }, id) {
      commit('SET_OPEN_ID', id)
    },
    toggleFolder({ commit }) {
      commit('TOGGLE')
    },
    clear({ commit }) {
      commit('CLEAR')
    }
  }
}
export default menu
