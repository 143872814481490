<template>
  <el-container class="base-container" :class="{ 'menu-collapse': menu.isCollapse }">
    <el-aside class="base-container-aside">
      <LeftSideMenu></LeftSideMenu>
    </el-aside>
    <el-container class="base-container-right">
      <el-header>
        <div class="header-wrap">
          <NavMenu></NavMenu>
          <MenuLv4></MenuLv4>
        </div>
      </el-header>
      <el-main>
        <GLScroll class="el-main-scroll" ref="scrollbar">
          <div class="main-container">
            <slot></slot>
          </div>
        </GLScroll>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import './subscriber'
import './components'
import { mapGetters } from 'vuex'
import Vue from 'vue'
const bus = new Vue()
export default {
  name: 'Layout',
  mounted() {
    this.addScrollEventListener()
  },
  provide() {
    return {
      bus
    }
  },
  methods: {
    addScrollEventListener() {
      const $elHeader = document.getElementsByClassName('el-header')[0]
      this.$refs.scrollbar.$children[0].wrap.addEventListener('scroll', function ({ target: { scrollLeft } }) {
        if (scrollLeft !== 0) scrollLeft += 40
        $elHeader.scrollLeft = scrollLeft
      })
    }
  },
  computed: {
    ...mapGetters(['menu'])
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/style/mixins.scss';
@import '~@/assets/style/collapse.scss';
.base-container {
  height: 100%;
  display: flex;
  background-color: $main-background-color;
  &-aside {
    position: relative;
    z-index: 1;
    flex: 0 0 $menu-width;
    text-align: left;
    transition: flex 0.06s;
  }
  &-right {
    flex: 1;
    height: 100%;
    display: flex;
    /deep/.el-header {
      // padding: 0 12px 0 40px;
      color: #333;
      text-align: center;
      height: auto !important;
      overflow-x: scroll;
      white-space: nowrap;
      @include hideScroll;
      padding: 0;
      .header-wrap {
        min-width: $right-min-width;
      }
    }
    .el-main {
      flex: 1;
      padding: 0;
      &-scroll {
        height: 100%;
      }
      .main-container {
        min-width: $right-min-width;
      }
    }
  }
}
</style>
