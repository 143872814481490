import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Login from '@/views/Login'
import E404 from '@/views/404'
import { utils } from 'glsx-common'
import { constants } from '@/common'
Vue.use(VueRouter)
const Path = Object.freeze({
  LOGIN: '/login',
  E404: '/404',
  OSS: '/oss',
  SP: '/sp' //Service Provider
})
// 路由
let constantRoutes = Object.freeze([
  {
    path: Path.LOGIN,
    name: 'Login',
    component: Login,
    meta: { title: '登录' }
  },
  {
    path: Path.E404,
    name: '404',
    component: E404,
    meta: { title: '404' }
  },
  {
    path: Path.OSS,
    name: 'OSS',
    component: Login,
    meta: { title: 'OSS登录' }
  },
  {
    path: Path.SP,
    name: 'SP',
    component: Login,
    meta: { title: '服务商登录' }
  },
  {
    path: '/',
    redirect: Path.LOGIN // 默认加载的路由
  }
  // { path: '*', redirect: Path.E404 }
])

const createRouter = () => {
  return new VueRouter({
    mode: 'history',
    routes: constantRoutes,
    scrollBehavior(to, from, savedPosition = { x: 0, y: 0 }) {
      return savedPosition
    }
  })
}

// 处理重复点击同一个路由报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//判断路由是否存在
VueRouter.prototype.hasRoute = function (path) {
  return this.getRoutes().some(route => {
    if (!route.path) return false
    //params  参数 路由
    if (route.path.includes(':')) {
      const routeSplit = route.path.split(':')
      const _path = routeSplit[0]
      const params = path.replace(_path, '').split('/')
      //比较路由参数个数
      if (params.length == routeSplit.length - 1) {
        return path == _path + params.join('/')
      }
    }
    return path == route.path
  })
}
VueRouter.prototype.constantRoutes = constantRoutes
/**
 * 重置注册的路由导航map
 */
VueRouter.prototype.reset = function () {
  const newRouter = createRouter()
  this.matcher = newRouter.matcher
}
VueRouter.prototype.ERR404 = { path: '*', redirect: Path.E404 }
const router = createRouter()
router.onError(err => {
  console.log(err)
})
router.beforeEach(async (to, from, next) => {
  const isPathIncluded = Object.values(Path).includes(to.path)
  if (isPathIncluded) {
    return next()
  }
  const session = store.getters.auth.session
  const storeSession = utils.storage.getSession(constants.SCRM_BASE_SESSION)
  if (storeSession && from === VueRouter.START_LOCATION && session === null) {
    await store.dispatch('init/init')
    return next({ ...to, replace: true })
  }
  if (storeSession) {
    const { $title } = to.query
    $title && (to.meta.title = $title)
    store.dispatch('app/setCurrentPage', to.meta)
    return next()
  }
  if (!session) {
    return next({ path: Path.LOGIN })
  }
})
// router.beforeEach(async (to, from, next) => {
//   if (Object.values(Path).includes(to.path)) return next()
//   const session = store.getters.auth.session
//   const storeSession = utils.storage.getSession(constants.SCRM_BASE_SESSION)
//   if (storeSession) {
//     if (from === VueRouter.START_LOCATION && session === null) {
//       await store.dispatch('init/init')
//       return next({ ...to, replace: true })
//     }
//     // if (router.hasRoute(to.path)) {
//     const { $title } = to.query
//     $title && (to.meta.title = $title)
//     store.dispatch('app/setCurrentPage', to.meta)
//     return next()
//     // }
//     // return next({ path: Path.E404, replace: true })
//   }
//   if (!session) {
//     next({ path: Path.LOGIN })
//   }
//   // next({ path: Path.LOGIN })
// })

export default router
