<!--
 * @Author: limin
 * @Date: 2022-11-07 11:16:08
 * @LastEditors: limin
 * @LastEditTime: 2022-11-14 16:27:22
 * @Description: 
-->
<template>
  <div class="com-login-sms-code-check">
    <el-dialog title="手机号验证" :visible.sync="dialogVisible" top="32vh" width="576px" height="292px" :destroy-on-close="true" :close-on-click-modal="false">
      <span class="notify">为了您的账号安全，请输入发送至 +86 {{ checkProps.mobile | encodeMobile }} 的4位验证码</span>
      <el-form :model="smsCodeCheckForm" :validate-on-rule-change="true" @keyup.enter.native="submitForm" :rules="rules" ref="smsCodeCheckForm" class="smsCodeCheckForm">
        <el-form-item prop="code">
          <el-input v-model.trim="smsCodeCheckForm.code" autocomplete="off" placeholder="请输入短信验证码" maxlength="4" @input="onInput" :validate-event="false"></el-input>
          <el-button type="text" @click="getSmsCode" v-show="!isShowtimeCounter" :disabled="isShowtimeCounter">获取验证码</el-button>
          <span class="time-counter" v-show="isShowtimeCounter">{{ counter }}s</span>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <div class="btn-wrapper">
          <el-button @click="cancle">取消</el-button>
          <el-button type="primary" @click="submitForm" :loading="loading">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { utils } from 'glsx-common'
import api from '@/api'
import { Message } from '@/plugins/element'
const counter = 60
export default {
  name: 'LoginSMSCodeCheck',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    checkProps: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      smsCodeCheckForm: {
        code: ''
      },
      isShowtimeCounter: false,
      counter,
      timer: null,
      loading: false,
      rules: {
        code: [{ validator: this.checkSmsCode }]
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(v) {
        this.$emit('update:visible', v)
      }
    }
  },
  methods: {
    /**
     * 点击提交按钮，开启表单验证
     * 1.验证码是否为空
     * 2.异步验证验证码是否正确
     * 以上两步均通过验证，关闭验证弹窗，触发验证成功事件
     */
    submitForm: utils.debounce(function () {
      this.loading = true
      this.$refs['smsCodeCheckForm'].validate(async valid => {
        this.loading = false
        if (valid) {
          this.cancle()
          this.$emit('onSuccess')
        }
      })
    }, 500),
    /**
     * 获取短信验证码
     */
    getSmsCode: utils.debounce(async function () {
      try {
        //打开计时器倒计时60秒
        this.startTimer()
        const { code, message } = await api.app.getsmscode(Object.assign({}, this.checkProps))
        let { response } = this.$config
        if (code == response.code_success_value) {
          Message.success('获取短信验证码成功')
        } else {
          Message.error(`获取短信验证码失败:${message}`)
        }
      } catch (error) {
        Message.error(`获取短信验证码失败:${error.message}`)
      }
    }, 1000),
    /**
     * 异步验证短信验证码
     * callback
     */
    async smsCodeCheck(callback) {
      try {
        const { code, message } = await api.app.smscodecheck(Object.assign({}, this.smsCodeCheckForm, this.checkProps))
        let { response } = this.$config
        if (code == response.code_success_value) {
          callback()
        } else {
          callback(new Error(message))
        }
      } catch (error) {
        callback(new Error(error.message))
      }
    },
    /**
     * 自定义验证码验证规则
     */
    checkSmsCode(rule, value, callback) {
      if (!value) {
        //不能为空
        return callback(new Error('请输入短信验证码'))
      } else {
        //异步验证
        return this.smsCodeCheck(callback)
      }
    },
    /**
     * 开启定时器 计时60秒
     */
    startTimer() {
      if (this.isShowtimeCounter) return
      this.isShowtimeCounter = true
      this.timer = setInterval(() => {
        this.counter--
        if (this.counter === 0) {
          this.clearTimer()
        }
      }, 1000)
    },
    /**
     * 关闭定时器
     */
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
        this.isShowtimeCounter = false
        this.counter = counter
      }
    },
    /**
     * 关闭短信验证码弹窗
     * 重置验证码输入框
     */
    cancle() {
      this.dialogVisible = false
      this.$refs['smsCodeCheckForm'].resetFields()
    },
    onInput(v) {
      const objFormItem = this.$refs['smsCodeCheckForm'].fields[0]
      if (objFormItem?.validateState === 'error') {
        objFormItem.clearValidate()
      }
      if (v) {
        this.smsCodeCheckForm.code = String(v).replace(/\D/g, '')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.com-login-sms-code-check {
  /deep/.el-dialog {
    box-shadow: 0px 10px 20px 0px rgba(88, 80, 255, 0.1);
    .notify {
      width: 488px;
      height: 24px;
      font-size: 16px;
      color: #333;
      line-height: 24px;
      margin-bottom: 24px;
      display: block;
      margin-top: 32px;
    }
    &__header {
      padding: 0;
      background-color: #fff;
      border-bottom: 1px solid #e5e5e5;
    }
    &__title {
      color: #333;
      font-weight: 600;
      height: 60px;
      line-height: 60px;
      text-align: center;
      padding-left: 20px;
    }
    &__close {
      font-size: 18px;
      font-weight: 600;
      &:hover {
        color: $main-color;
      }
    }
    &__body {
      width: 488px;
      margin: 0 auto;
      text-align: center;
      .el-form {
        &-item {
          margin-bottom: 18px;
          &__label {
            line-height: 30px;
            float: none;
          }
          &__content {
            width: 284px;
            margin: 0 auto;
            .el-input__inner {
              &:focus {
                border-color: $main-color;
              }
            }
            .el-button--text {
              color: $main-color;
              position: absolute;
              right: 10px;
            }
          }
          .time-counter {
            display: block;
            position: absolute;
            right: 10px;
            top: 0;
            text-align: center;
            font-size: 14px;
            letter-spacing: 1px;
            color: #ccc;
          }
        }
      }
    }
    &__footer {
      text-align: center;
      border-top: 1px solid #e5e5e5;
      margin-top: 48px;
      .btn-wrapper {
        margin: 0 auto;
        width: 284px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        .el-button {
          min-width: 60px;
          height: 32px;
          padding: 6px 16px;
          &.el-button--primary {
            background-color: $main-color;
            border-color: $main-color;
            &:hover,
            &:focus {
              border-color: #7777f3;
              background-color: #7777f3;
            }
          }
          &.el-button--default {
            &:hover,
            &:focus {
              border-color: #7777f3;
              color: $main-color !important;
            }
          }
        }
      }
    }
  }
}
</style>
