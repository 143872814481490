<template>
  <div class="scrm-base-nav flex-row">
    <div class="flex-1 none-border nav-left">
      <GLBreadcrumb3></GLBreadcrumb3>
    </div>
    <div class="flex-row flex-items-center nav-right">
      <template v-if="$config.productCode !== 'PC0008'">
        <div class="menu-icons">
          <span class="menu-icon">
            <el-badge class="item">
              <i class="i i-message" />
            </el-badge>
          </span>
          <el-popover placement="bottom" trigger="click">
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 86px">
              <img :src="`${$config.cdn}/assets/images/empty.png`" style="width: 40px; height: 24px" />
              <span style="margin-top: 4px">暂无消息</span>
            </div>
            <span class="nav-text" slot="reference" style="cursor: pointer">消息</span>
          </el-popover>
        </div>
        <!-- PC0008 服务商 屏蔽联系我们 -->
        <div class="menu-icons">
          <span class="menu-icon">
            <el-badge class="item">
              <i class="i i-contact" />
            </el-badge>
          </span>
          <div class="nav-text">
            <el-popover placement="bottom" width="228" height="316" trigger="click">
              <NavContact></NavContact>
              <a href="javascript:void(0);" slot="reference">联系我们</a>
            </el-popover>
            <span class="sprator">|</span>
            <!-- <a href="https://www.yuque.com/docs/share/ad640d83-50d9-49cc-a92c-0a5230fedd17" target="blank">营销学院</a> -->
            <NavUpdateLog />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="nav-text" style="margin-right: 12px">
          <NavUpdateLog />
        </div>
      </template>
      <div class="flex-row flex-items-center">
        <i class="i i-avatar" />
      </div>
      <template v-if="auth.session">
        <el-tooltip class="item" effect="dark" popper-class="base-merchant-name-tooltip" :placement="tip.placement" v-if="tip.isShow">
          <div slot="content" v-html="tip.content"></div>
          <span class="nav-text nav-text-avatar">{{ tip.value }}</span>
        </el-tooltip>
        <span class="nav-text nav-text-avatar" v-else>{{ tip.value }}</span>
        <el-dropdown @command="handleCommand" trigger="click" :hide-on-click="false" @visible-change="dropdownVisibleChange">
          <span class="el-dropdown-link">
            <i class="i i-arrow-down" :class="{ drOpen }"></i>
          </span>
          <el-dropdown-menu slot="dropdown" ref="drop-main">
            <el-dropdown-item :command="item.icon" v-for="item in dropDown" :key="item.icon" @mouseenter.native.stop="mouseenter(item)" @mouseleave.native.stop="mouseleave" :disabled="item.disabled" :title="item.title.length > 7 ? item.title : ''">
              <i class="i" :class="genClass(item)" />
              {{ item.title }}
            </el-dropdown-item>
          </el-dropdown-menu>
          <el-dropdown-menu ref="drop-sub" class="drop-sub" :style="subStyle">
            <el-dropdown-item :command="item" v-for="item in auth.session.loginList" :key="item.accounttypeId" :title="item.accounttypeName.length > 7 ? item.accounttypeName : ''">
              <i class="i" :class="{ 'i-checked': currentMerchant.accounttypeId == item.accounttypeId }" />
              {{ item.accounttypeName }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </div>
    <NavUpdatePassWord :visible.sync="dialogVisible"></NavUpdatePassWord>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Message } from '@/plugins/element'
import { utils } from 'glsx-common'
// import api from '@/api'
import { constants } from '@/common'
const SUBSTYLE = Object.freeze({ opacity: 0 })
const dropDown = [
  {
    title: '',
    icon: 'exchange'
  },
  {
    title: '修改密码',
    icon: 'edit'
  },
  {
    title: '退出账户',
    icon: 'logoff'
  }
]
export default {
  name: 'NavMenu',
  data() {
    return {
      dropDown,
      hoverIcon: '',
      active: '',
      drOpen: false,
      subStyle: SUBSTYLE,
      dialogVisible: false,
      currentMerchant: {}
    }
  },
  computed: {
    ...mapGetters(['auth']),
    tip() {
      const { userType, userName, merchantName } = this.auth?.session
      const value = [undefined, merchantName, userName][userType] || merchantName
      const content = value.slice(0, 25) + '</br>' + value.slice(25)
      const isShow = value.length > 11
      return { value, content, isShow, placement: 'bottom-end' }
    }
  },
  beforeDestroy() {
    this.resetIcon()
  },
  mounted() {
    this.setCurrent()
  },
  methods: {
    ...mapActions('app', ['logout', 'formatSave', 'setLoading']),
    ...mapActions('auth', ['permission', 'saveSession']),
    async handleCommand(command) {
      if (typeof command === 'string') {
        this.active = command
        return await this[command]()
      }
      await this.changeAccount(command)
    },
    async changeAccount(merchant) {
      if (this.currentMerchant.accounttypeId == merchant.accounttypeId) return
      const { merchantProTreeResponses: menus } = merchant.products[0]
      if (menus.length) {
        const { menuFormat, defaultPath } = await this.formatSave(menus).catch(error => Message.error(error.message))
        if (!menuFormat) return
        const { session } = this.auth
        session.loginList.forEach(item => (item.active = item.accounttypeId == merchant.accounttypeId))
        session.defaultPath = defaultPath
        this.saveSession(session)
        const isLegalPath = this.validatePath(menuFormat)
        ;[() => location.replace(defaultPath), () => location.reload()][+isLegalPath].call()
      } else {
        const { MESSAGE } = constants.SCRM_BASE_ERRORS.NO_PERMISSION_MENUS
        Message.error(MESSAGE)
      }
    },
    async exchange() {
      ;[this.showSub, this.hideSub][+this.$refs['drop-sub'].$el.style.opacity].call()
    },
    async edit() {
      this.dialogVisible = true
    },
    logoff() {
      this.logout()
    },
    dropdownVisibleChange(isShow) {
      this.drOpen = isShow
      if (!isShow) {
        this.hideSub()
        this.resetIcon()
      }
    },
    showSub() {
      this.showOrHide(1)
    },
    hideSub() {
      this.showOrHide(0)
      this.active = ''
      this.$refs['drop-main'].$children[0].$el.blur()
    },
    showOrHide(opacity) {
      const {
        ['drop-sub']: {
          $el: { offsetWidth }
        },
        ['drop-main']: {
          $el: { offsetLeft }
        },
        left = offsetLeft - opacity * offsetWidth + 'px'
      } = this.$refs
      this.subStyle = { left, opacity }
    },
    genClass(item) {
      const { icon, _icon = `i-${icon}` } = item
      if (item.disabled) return `${_icon}-disabled`
      const active = this.active == icon || this.hoverIcon == icon
      return `${_icon}${['', '-active'][+active]}`
    },
    mouseenter: function (item) {
      if (this.hoverIcon === item.icon) return
      this.hoverIcon = item.icon
    },
    mouseleave: function () {
      this.hoverIcon = ''
    },
    resetIcon() {
      this.active = ''
      this.hoverIcon = ''
    },
    validatePath(menuFormat) {
      const [, name, ...path] = location.pathname.split('/')
      const mod = menuFormat.find(menu => menu.name === name)
      if (mod) {
        const menu = utils.array.valInDeep(mod.children, `/${path.join('/')}`, 'path', 'children')
        return menu && menu.length
      }
      return false
    },
    setCurrent() {
      const { loginList, disabled = loginList.length == 1 } = this.auth.session
      const merchant = loginList.find(type => type.active)
      Object.assign(this.dropDown[0], { title: merchant.accounttypeName, disabled })
      this.currentMerchant = merchant
    }
  }
}
</script>
<style lang="scss">
$tip-color: #666 !important;
.base-merchant-name-tooltip {
  background-color: $tip-color;
  /* 修改箭头位置和背景色 */
  &.el-tooltip__popper.is-dark .popper__arrow {
    left: unset !important;
    right: 1em !important;
    &:after {
      border-bottom-color: $tip-color;
    }
  }
}
</style>
<style lang="scss" scoped>
@import '~@/assets/style/mixins.scss';
@mixin icon($color: rgb(153, 153, 153), $font-size: 20px) {
  font-size: 20px;
  font-weight: bolder;
  color: rgb(153, 153, 153);
}
.scrm-base-nav {
  height: $header-height;
  background-color: #fff;
  @include leftpadding;
  /deep/.nav-text {
    display: flex;
    margin-left: 2px;
    &,
    a,
    .sprator {
      font-size: 14px;
      font-weight: 500;
      color: #3b457f;
    }
    .sprator {
      margin: 0 4px;
    }
    &-avatar {
      margin-left: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 11em;
    }
  }
  .nav-left {
    display: flex;
    align-items: center;
  }
  .menu-icons {
    padding-right: 20px;
    display: flex;
    align-items: center;
  }

  .el-dropdown {
    .i-arrow-down {
      @include icon;
      margin: 0 10px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &.drOpen {
        transform: rotate(-180deg);
      }
    }
  }
}
.el-dropdown-menu {
  background: #fff;
  border: 1px solid #e4e7ed;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
  &__item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 10em;
    min-width: 8em;
    user-select: none;
    i.icon {
      font-weight: bold;
      font-size: 16px;
    }
    &:focus,
    &:not(.is-disabled):hover {
      background-color: #ebeaff;
      color: $main-color;
    }
  }
  &.drop-sub {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    /deep/.popper__arrow::after {
      display: none;
    }
  }
}
</style>
