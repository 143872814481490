import { Http, utils } from 'glsx-common'
import { messenger, history } from '@/global'
import { configSwitch } from '@/config'
import { This } from '@/main'
import store from '@/store'
import router from '@/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ ease: 'ease', speed: 300, showSpinner: false })
// import { actions } from './globalState'
/* 重构apps */
export const filterApps = microApps => {
  const { auth, track, session = auth.session, tracker = track.tracker } = store.getters
  const apps = microApps.reduce((apps, item) => {
    const { name, entry, id, activeRule = `/${name}` } = item
    apps.push({
      id,
      name,
      entry,
      activeRule,
      props: {
        routerBase: activeRule, // 下发基础路由
        globalState: { routers: filter(activeRule), session, configSwitch, parent: This }, // 下发全局数据方法
        common: { Http, utils, tracker },
        messenger,
        history
      },
      container: '#subapp-viewport',
      loader: loading => setTimeout(() => store.dispatch('app/setLoading', loading), [200, 0][+loading])
    })
    return apps
  }, [])
  return apps
}
function filter(routerBase) {
  const routers = router
    .getRoutes()
    .filter(route => route.path.startsWith(`${routerBase}/`))
    .reduce((collect, { path, compath, name, meta }) => {
      path = path.slice(routerBase.length)
      compath = path.split('/:')[0]
      collect.push({ path, compath, name, meta })
      return collect
    }, [])
  return routers
}

/* qiankun全局声明周期钩子 */
export const microConfig = {
  beforeMount: NProgress.start,
  afterMount: NProgress.done
}
