import Vue from 'vue'
import GLContainer from './GLContainer'
import GLContextmenu from './GLContextmenu'
// import GLFadein from './GLFadein'
import GLScroll from './GLScroll'
// import GLDialogURL from './GLDialogURL'

// const components = [GLContainer, GLContextmenu, GLFadein, GLScroll, GLDialogURL]
const components = [GLContainer, GLContextmenu, GLScroll]

const install = function (Vue) {
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}
Vue.use({
  install,
  GLContainer,
  GLContextmenu,
  // GLFadein,
  GLScroll
  // GLDialogURL
})
