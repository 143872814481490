/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: limin
 * @Date: 2021-08-25 14:52:15
 * @LastEditors: limin
 * @LastEditTime: 2023-09-19 15:03:49
 * @FilePath: \scrm-base\src\global\history.js
 */
import store from '@/store'
import { utils } from 'glsx-common'
/**
 * 跨应用路由跳转
 * @param {String} href url地址
 * @param {Object} stateObj 状态参数 可以通过history.state读取
 * @param {String} title 标题 暂时没有用
 */
function push(menu) {
  let stateObj, title, url, id
  function val(to) {
    if (to) {
      stateObj = to.stateObj
      title = to.title
      id = to.id
      url = to.path
      if (id) {
        store.dispatch('app/setCurrentPage', to)
      }
    }
  }
  if (typeof menu === 'string') {
    url = menu
  } else {
    val(menu)
  }
  if (!url) {
    throw new Error('history.push:必须传入URL')
  }
  if (!url.startsWith('/')) {
    url = '/' + url
  }
  let to = findObjTo(url)
  to[0] && val(to[0])
  if (!url) {
    throw new Error('跳转页面失败,请检查页面参数是否正确')
  }
  if (!utils.isEmptyObject(menu.params)) {
    url = url + '?' + utils.genUrlParams(menu.params)
  }
  window.history.pushState(stateObj, title, url)
}

function findObjTo(url) {
  return utils.array.valInDeep(store.getters.app.menuList, url, 'path', 'children')
}

export {
  push // 跨应用路由跳转
}
