import { render, staticRenderFns } from "./LeftSideMenuLv1Item.vue?vue&type=template&id=3914c33d&scoped=true&"
import script from "./LeftSideMenuLv1Item.vue?vue&type=script&lang=js&"
export * from "./LeftSideMenuLv1Item.vue?vue&type=script&lang=js&"
import style0 from "./LeftSideMenuLv1Item.vue?vue&type=style&index=0&id=3914c33d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3914c33d",
  null
  
)

export default component.exports