/*
 * @Descripttion:
 * @version:
 * @Author: limin
 * @Date: 2021-06-07 14:12:32
 * @LastEditors: limin
 * @LastEditTime: 2021-06-08 16:55:43
 */
// 根据环境引入不同配置 process.env.VUE_APP_ENV
const environment = process.env.NODE_ENV || 'production'
let config = require('./env.' + environment)
module.exports = config
