/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: limin
 * @Date: 2021-08-13 14:12:11
 * @LastEditors: limin
 * @LastEditTime: 2021-09-23 21:22:21
 * @FilePath: \scrm\scrm-base\src\global\messenger.js
 */
import { push } from './history'
import { Subject } from 'rxjs'

/**
 * Messenger类扩展了Subject类，用于发送和接收消息。
 */
class Messenger extends Subject {
  /**
   * send方法用于发送消息，并将消息推送到浏览器历史记录中。
   * @param {Object} msg - 要发送的消息对象。
   * @param {string} msg.route - 要推送到浏览器历史记录中的路由。
   */
  send(msg) {
    const { route } = msg
    if (route) {
      push(route)
    }
    this.next(msg)
  }
}
const messenger = new Messenger()

messenger.subscribe(v => {
  console.log(`监听到子应用${v.from}发来消息：`, v)
})
export default messenger
