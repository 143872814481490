/*
 * @Descripttion:
 * @version:
 * @Author: limin
 * @Date: 2021-06-07 14:12:32
 * @LastEditors: limin
 * @LastEditTime: 2022-12-15 15:41:46
 */
// 本地环境配置
module.exports = {
  title: 'scrm-base',
  response: {
    data_key: 'data',
    message_key: 'message',
    code_key: 'code',
    code_success_value: '0',
    code_message_map: {}
  },
  request: {
    // baseURL: 'http://192.168.2.59:8315/rop-scrm-sso/router',
    baseURL: 'https://oss-config.dev.glsx.com.cn/mock/6136bea6ceeb5a5c2bec6950/base',
    // 设置超时时间
    timeout: 180000,
    // 携带凭证
    withCredentials: true,
    // 返回数据类型
    responseType: 'json'
  },
  sign: {
    appKey: '48e5e13229b82c1b4e6e8c96151f0637',
    v: '1.0.0',
    format: 'json',
    source: 'scrm'
  },
  secret: 'c24619ed7fef02a0ae16328146bca5f97cc6493957a2137b',

  cdn: 'https://www.xxx.cn/static',

  productCode: 'PC0001',
  loginSource: 'SCRM'
}
