import { http } from '@/main'
const login = data => {
  data.method = 'scrm.api.sso.user.loginCode'
  return http({ data, returnAll: true })
}
const choose = data => {
  data.method = 'scrm.api.sso.user.login.select'
  return http({ data, returnAll: true })
}
const exchange = data => {
  data.method = 'scrm.api.sso.user.login.change'
  return http({ data, returnAll: true })
}
const permission = data => {
  data.method = 'scrm.api.sso.user.permission.get'
  if (+process.env.VUE_APP_MOCK) {
    return http({ url: process.env.VUE_APP_MOCK_URL, method: 'GET', returnAll: true })
  }
  return http({ data, returnAll: true })
}
const code = data => {
  data.method = 'scrm.api.sso.user.login.getVerifyCode'
  return http({ data })
}
const logout = data => {
  data.method = 'scrm.api.sso.user.logout'
  return http({ data })
}
const updatePassword = data => {
  data.method = 'scrm.api.sso.user.updatePassword'
  return http({ data, returnAll: true })
}
const verifyPhone = data => {
  data.method = 'scrm.api.sso.user.login.account.phone.get'
  return http({ data, returnAll: true })
}
const getsmscode = data => {
  data.method = 'scrm.api.sso.user.sms.get.code'
  return http({ data, returnAll: true })
}
const smscodecheck = data => {
  data.method = 'scrm.api.sso.user.sms.code.check'
  return http({ data, returnAll: true })
}
export default {
  login,
  logout,
  choose,
  exchange,
  permission,
  code,
  updatePassword,
  verifyPhone,
  getsmscode,
  smscodecheck
}
