<template>
  <el-menu-item-group :title="parentTitle" class="scrm-base-submenu-item" v-if="menuItems">
    <el-menu-item v-for="item in menuItems.noHidden()" :key="item.id" :index="item.id" @click.native.stop="handleSelect(item)">
      <span>{{ item.title }}</span>
    </el-menu-item>
  </el-menu-item-group>
</template>

<script>
export default {
  name: 'SubMenuItem',
  props: {
    parentTitle: {
      type: String,
      required: false,
      default: ''
    },
    menuItems: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleSelect({ path }) {
      if (path) {
        this.$router.push(path)
      }
    }
  },
  mounted(){
    console.log(this.menuItems);
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/style/collapse.scss';
$active-background-color: rgba(88, 80, 255, 0.2) !important;
$menu-item-width: 173px !important;
.scrm-base-submenu-item {
  /deep/.el-menu-item-group__title {
    display: none;
  }
  /deep/ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    .el-menu-item {
      width: $menu-item-width;
      min-width: $menu-item-width;
      height: 40px;
      line-height: 40px;
      align-self: center;
      border-radius: 4px;
      span {
        color: #666;
        font-size: 16px;
      }
      &:hover,
      &.is-active {
        outline: 0 !important;
        color: $main-color !important;
        background-color: $active-background-color;
        border-bottom: none;
        span {
          color: $main-color !important;
        }
      }
      &:hover:not(.is-active) {
        background-color: transparent !important;
      }
    }
  }
}
</style>
<style lang="scss">
$width: 150px;
@mixin popup_width {
  width: $width !important;
  min-width: $width !important;
}
.el-menu--vertical {
  &.el-submenu-popper-base {
    .el-menu--popup {
      @include popup_width;
      margin-left: -8px;
      box-shadow: 0px 6px 20px 0px rgba(122, 122, 122, 0.2);
      border-radius: 4px;
      .scrm-base-submenu-item {
        .el-menu-item {
          @include popup_width;
          padding-left: 20px !important;
          border-radius: 0;
        }
        .el-menu-item-group__title {
          display: block;
        }
      }
    }
  }
}
</style>
